import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

export const GetRandomTipOfDay = async (tipType) => {
	//  console.log("getRandomTipOfDay");
	const { data } = await apollo.query({
		query: gql`
			query ($TipType: Int!) {
				GetRandomTipOfDay(TipType: $TipType) {
					id
					number
					description
					category
					categoryName
				}
			}
		`,
		variables: {
			TipType: parseInt(tipType)
		},
		fetchPolicy: 'network-only'
	});

	return data.GetRandomTipOfDay;
};

export const AddUpdTipOfDay = async (number, description, category) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($Number: Int!, $Description: String!, $Category: Int!) {
				AddUpdTipOfDay(Number: $Number, Description: $Description, Category: $Category) {
					id
					number
					description
					category
					categoryName
					publishedOn
					publisher {
						name
						firstName
					}
				}
			}
		`,
		variables: {
			Number: parseInt(number),
			Category: parseInt(category),
			Description: description
		}
		//fetchPolicy: "network-only"
	});

	return data.AddUpdTipOfDay;
};

export const DelTipOfDay = async (number) => {
	const { data } = await apollo.mutate({
		mutation: gql`
			mutation ($Number: Int!) {
				DelTipOfDay(Number: $Number)
			}
		`,
		variables: {
			Number: parseInt(number)
		}
		//  fetchPolicy: "network-only"
	});

	return data.DelTipOfDay;
};

export const GetTipsOfDay = async () => {
	//        console.log("GetTipsOfDay");
	const { data } = await apollo.query({
		query: gql`
			query {
				GetTipsOfDay {
					id
					number
					description
					category
					categoryName
					publishedOn
					publisher {
						name
						firstName
					}
				}
			}
		`,
		variables: {},
		fetchPolicy: 'network-only'
	});

	return data.GetTipsOfDay;
};

export const GetTgsTipsOfDay = async () => {
	//        console.log("GetTipsOfDay");
	const { data } = await apollo.query({
		query: gql`
			query {
				GetTgsTipsOfDay {
					id
					number
					description
					category
					categoryName
					publishedOn
				}
			}
		`,
		variables: {},
		fetchPolicy: 'network-only'
	});

	return data.GetTgsTipsOfDay;
};
